import type { Space } from '@cocoonspace/sdk-js/types/space'
import { ShoppingCart } from 'lucide-react'
import { ActionIcon } from '~/components/action-icon'
import { Button } from '~/components/ui/button'
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '~/components/ui/popover'
import type { Slot } from '../../../_hooks/use-time-slots.hook'
import { EventCellRender } from '../../molecules/event-cell-render'

export const Recap = ({
	spaces,
	selectedSlots,
	onDelete,
}: {
	spaces?: Space[]
	selectedSlots: Slot[]
	onDelete: (slot: Slot) => void
}) => {
	return (
		<Popover modal>
			<PopoverTrigger asChild>
				<Button
					variant='ghost'
					size='icon'
					className='relative'
				>
					<ShoppingCart className='size-4' />

					<div className='-right-1 -top-1 absolute flex h-5 w-5 items-center justify-center rounded-full bg-primary text-sm text-white'>
						{selectedSlots.length}
					</div>
				</Button>
			</PopoverTrigger>

			<PopoverContent className='max-h-[400px] overflow-y-auto p-0'>
				<ul className='flex flex-col divide-y'>
					{selectedSlots.map((slot, i) => (
						<div
							key={i}
							className='flex flex-row items-center gap-4 px-4 py-2'
						>
							<EventCellRender
								event={{
									...slot,
									space: spaces?.find((space) => space.id === slot.space_id),
								}}
								titleClassName='text-sm'
								slotClassName='text-xs'
							/>

							<Button
								variant='ghost'
								size='icon'
								className='ml-auto'
								onClick={() => onDelete(slot)}
							>
								<ActionIcon actionName='delete' />
							</Button>
						</div>
					))}
				</ul>
			</PopoverContent>
		</Popover>
	)
}
