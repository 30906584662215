import { Trash2 } from 'lucide-react'
import { Input } from '../ui/input'
import { Label } from '../ui/label'

export const StringFilter = ({
	label,
	name,
	value,
	onChange,
}: {
	label?: string
	name: string
	value: string
	onChange: (value: string | null) => void
}) => (
	<div className='flex flex-col gap-1'>
		{!!label && <Label htmlFor={name}>{label}</Label>}

		<div className='relative'>
			<Input
				id={name}
				name={name}
				value={value || ''}
				onChange={(e) => onChange(e.target.value || '')}
			/>

			{!!value && (
				<button
					type='button'
					className='absolute top-px right-4 bottom-px bg-backround'
					onClick={() => onChange(null)}
				>
					<Trash2 className='h-4 w-4' />
				</button>
			)}
		</div>
	</div>
)
