import { Trash2 } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import { Label } from '../ui/label'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select'

export const SelectFilter = <T extends string>({
	label,
	name,
	value,
	options,
	onChange,
}: {
	label: string
	name: string
	value: string
	options: ({ value: T; label?: string } | T)[]
	onChange: (value: T | null) => void
}) => {
	const { t } = useTranslation()

	return (
		<div className='flex flex-col gap-1'>
			{!!label && <Label htmlFor={name}>{label}</Label>}

			<Select
				value={value || ''}
				onValueChange={(value) => onChange(value as T)}
			>
				<div className='relative'>
					<SelectTrigger className='flex min-w-[150px] flex-row items-center gap-2'>
						<SelectValue />
					</SelectTrigger>

					{!!value && (
						<button
							type='button'
							className='absolute top-px right-4 bottom-px bg-backround'
							onClick={() => onChange(null)}
						>
							<Trash2 className='size-4' />
						</button>
					)}
				</div>

				<SelectContent>
					{options?.map((option, i) => {
						if (typeof option === 'string') {
							return (
								<SelectItem
									key={i}
									value={option}
								>
									{t(`values:values.${name}.${option}`)}
								</SelectItem>
							)
						}

						return (
							<SelectItem
								key={i}
								value={option.value}
							>
								{option.label || option.value}
							</SelectItem>
						)
					})}
				</SelectContent>
			</Select>
		</div>
	)
}
