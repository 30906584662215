import { X } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import { Checkbox } from '~/components/ui/checkbox'
import type {
	AvailableTimeSlots,
	Slot,
} from '../../../_hooks/use-booking-simulation.hook'
import { genDateTimeUniqueKey } from '../../../_utils/gen-date-time-unique-key'

export const TimeslotCellRenderer = ({
	value,
	selectedTimeslots,
	onCheck,
}: {
	value: AvailableTimeSlots['availabilities'][number] & {
		day: string
		start: string
		end: string
		space_id: string
	}
	selectedTimeslots: Slot[]
	onCheck: (timeSlot: Slot, isSelected: boolean) => void
}) => {
	const { t } = useTranslation()
	const isAvailable = value?.available

	const isSelected = useMemo(() => {
		if (!value) return false

		return !!selectedTimeslots.find(
			(slot) =>
				genDateTimeUniqueKey(slot) === genDateTimeUniqueKey(value!) &&
				slot.space_id === value?.space_id,
		)
	}, [selectedTimeslots, value])

	const price = useMemo(() => {
		const priceHour = value?.price_hour || 0
		const billedMinutes = value?.billed_minutes || 0

		return priceHour * (billedMinutes / 60)
	}, [value])

	const onCheckChange = useCallback(() => {
		if (!value) return

		const slot = {
			space_id: value.space_id,
			day: value.day,
			start: value.start,
			end: value.end,
		}

		onCheck(slot, !isSelected)
	}, [value, isSelected, onCheck])

	if (isAvailable) {
		return (
			<label className='flex h-full w-full cursor-pointer items-center gap-2 px-6 hover:bg-primary/10'>
				<Checkbox
					checked={isSelected}
					onCheckedChange={onCheckChange}
				/>

				<div className='flex-1 text-center text-xs'>
					{t('common:currency.full', { val: price / 100 }).replace(',00', '')}
				</div>
			</label>
		)
	} else {
		return (
			<div className='flex h-full w-full items-center justify-center'>
				<X className='!stroke-[2px] mr-2 size-4 text-primary text-xl' />
			</div>
		)
	}
}
